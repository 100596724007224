<template>
  <div>
    <div style="width: 100%" class="wrapper map mb-5" justify="center">
      <div class="searchAddress">
        <vue-google-autocomplete
          v-if="mapLoaded"
          ref="address"
          id="map"
          v-model="attraction.address"
          class="google-autocomplete"
          classname="form-control"
          :placeholder="'attractions_label_search' | localize"
          v-on:placechanged="getAddressData"
          v-on:inputChange="autocompleteInput"
          :style="
            addressError.length
              ? 'color: red; border: 1px solid red !important; border-bottom-right-radius: 0px; border-bottom-left-radius: 0px;'
              : 'color: #e62076; border: 1px solid #e62076 !important'
          "
        >
        </vue-google-autocomplete>
        <v-expand-transition>
          <p
            v-if="addressError.length"
            class="pt-2 pl-2 pb-2"
            style="color: red; background: rgba(255, 255, 255, 0.6)"
          >
            {{ addressError.toString() }}
          </p>
        </v-expand-transition>
      </div>
      <GmapMap
        :center="coordinates"
        :zoom="zoom"
        :options="options"
        ref="map"
        map-type-id="roadmap"
        style="width: 100%; height: 400px"
        @click="addMarker"
      >
        <GmapMarker
          :icon="svgMarker"
          :position="attraction.marker.position"
          :clickable="true"
          :draggable="true"
        >
        </GmapMarker>
      </GmapMap>
    </div>
    <v-row no-gutters>
      <v-btn text class="ml-auto" @click="cancel()">
        {{ "button_cancel" | localize }}
      </v-btn>
      <v-btn
        v-if="!editAttraction"
        color="#E62076"
        dark
        @click="next()"
        :loading="isDisabled"
        >{{ "cart_details_button_next" | localize }}</v-btn
      >
      <v-btn
        v-if="editAttraction"
        color="#E62076"
        :disabled="isDisabled"
        dark
        @click="$emit('update')"
        >{{ "button_update" | localize }}</v-btn
      >
    </v-row>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { gmapApi } from "vue2-google-maps";
import axios from "axios";
import cityMixin from "@/mixins/cityMixin";
export default {
  components: {
    VueGoogleAutocomplete,
  },
  mixins: [cityMixin],
  props: {
    editAttraction: {
      require: false,
    },
  },
  data: () => ({
    isDisabled: false,
    coordinates: { lat: 20.672664, lng: -103.343997 },
    zoom: 11,
    mapLoaded: false,
    map: null,
    addressError: [],
    attraction: {
      marker: {},
      address: "",
    },
    options: {
      zoomControl: true,
      mapTypeControl: true,
      streetViewControl: true,
      mapTypeControlOptions: {
        position: 6,
      },
    },
    svgMarker: {
      path: "M18.27 6c1.01 2.17.78 4.73-.33 6.81c-.94 1.69-2.29 3.12-3.44 4.69c-.5.7-1 1.45-1.37 2.26c-.13.27-.22.55-.32.83c-.1.28-.19.56-.28.84c-.09.26-.2.57-.53.57c-.39 0-.5-.44-.58-.74c-.24-.73-.48-1.43-.85-2.1c-.42-.79-.95-1.52-1.49-2.23L18.27 6M9.12 8.42l-3.3 3.92c.61 1.29 1.52 2.39 2.39 3.49c.21.25.42.51.62.78L13 11.67l-.04.01c-1.46.5-3.08-.24-3.66-1.68c-.08-.17-.14-.37-.18-.57a3.05 3.05 0 0 1 0-1v-.01m-2.54-3.8l-.01.01c-1.62 2.05-1.9 4.9-.93 7.31L9.63 7.2l-.05-.05l-3-2.53m7.64-2.26L11 6.17l.04-.01c1.34-.46 2.84.12 3.52 1.34c.15.28.27.58.31.88c.06.38.08.65.01 1.02v.01l3.2-3.8a6.988 6.988 0 0 0-3.85-3.24l-.01-.01M9.89 6.89l3.91-4.65l-.04-.01C13.18 2.08 12.59 2 12 2c-1.97 0-3.83.85-5.15 2.31l-.02.01l3.06 2.57z",
      fillColor: "#E62076",
      fillOpacity: 1,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
      anchor: { x: 12, y: 25 },
    },
  }),
  created() {
    if (this.mazatlan) {
      this.coordinates = { lat: 23.253522, lng: -106.411635 };
      //this.marker = {id: 1, position: {lat: 23.253522, lng: -106.411635}}
      this.zoom = 12;
    }
    if (this.editAttraction) {
      this.attraction.marker = {
        id: 1,
        position: {
          lat: parseFloat(this.editAttraction.geo_lat),
          lng: parseFloat(this.editAttraction.geo_lon),
        },
      };
    }
    this.getStreetAddressFrom();
    if (this.google) {
      this.mapLoaded = true;
    }
  },
  watch: {
    google: function () {
      this.mapLoaded = true;
    },
    "attraction.address": {
      deep: true,
      handler() {
        if (this.address !== "") {
          this.addressError = [];
        }
      },
    },
    attraction: {
      deep: true,
      handler() {
        if (this.editAttraction) {
          let updated_attr = {
            address: this.attraction.address,
            geo_lat: this.attraction.marker.position.lat,
            geo_lon: this.attraction.marker.position.lng,
          };
          console.log(updated_attr);
          this.$emit("changedAttraction", updated_attr);
        }
      },
    },
  },
  computed: {
    google: gmapApi,
  },
  methods: {
    async getStreetAddressFrom() {
      var { data } = await axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          this.attraction.marker.position.lat +
          "," +
          this.attraction.marker.position.lng +
          "&key=AIzaSyDjv7izdGTG6V_5XF7VwqJOzbjEYYiJjKc"
      );
      this.$refs.address.$refs.autocomplete.value =
        data.results[0].formatted_address;
      this.attraction.address = data.results[0].formatted_address;
      console.log(this.attraction.address);
    },
    getAddressData(e) {
      //this.address = e.addressData;
      this.attraction.marker.position = { lat: e.latitude, lng: e.longitude };
      this.coordinates = { lat: e.latitude, lng: e.longitude };
      this.getStreetAddressFrom();
    },
    addMarker(e) {
      this.attraction.marker = {
        id: 1,
        position: { lat: e.latLng.lat(), lng: e.latLng.lng() },
      };
      this.getStreetAddressFrom();
    },
    update() {
      let data = {
        content: {
          en: {
            title: this.editAttraction.content[0].title,
            description: this.editAttraction.content[0].description,
            short_description: this.editAttraction.content[0].short_description,
            refund_policy: this.editAttraction.content[0].refund_policy,
            booking_requirements:
              this.editAttraction.content[0].booking_requirements,
          },
          es: {
            title: this.editAttraction.content[1].title,
            description: this.editAttraction.content[1].description,
            short_description: this.editAttraction.content[1].short_description,
            refund_policy: this.editAttraction.content[1].refund_policy,
            booking_requirements:
              this.editAttraction.content[1].booking_requirements,
          },
        },
        address: this.attraction.address,
        working_days: this.editAttraction.working_days,
        working_hours: this.editAttraction.working_hours,
        closest_stop: this.editAttraction.closest_stop,
        price: parseInt(this.editAttraction.price),
        child_price: parseInt(this.editAttraction.child_price),
        geo_lat: parseFloat(this.attraction.marker.position.lat),
        geo_lon: parseFloat(this.attraction.marker.position.lng),
        images: this.editAttraction.images,
        status: this.editAttraction.status,
        business_uid: this.editAttraction.business_uid,
        is_visible: this.editAttraction.is_visible,
        maximum_available_discount:
          this.editAttraction.maximum_available_discount,
      };
      console.log(data);
      this.$emit("update", data);
    },
    cancel() {
      this.$emit("close_modal");
    },
    next() {
      if (this.attraction.address !== "" && this.attraction.marker.position) {
        this.isDisabled = true;
        this.$emit(
          "next",
          this.attraction.marker.position,
          this.attraction.address
        );
      } else if (
        this.attraction.address == "" &&
        !this.attraction.marker.position
      ) {
        this.addressError.length
          ? ""
          : this.addressError.push("Address field is required");
      } else {
        this.addressError.length
          ? ""
          : this.addressError.push("Place choose address");
      }
    },
    autocompleteInput() {
      this.addressError = [];
      setTimeout(() => {
        this.attraction.address = this.$refs.address.$refs.autocomplete.value;
      }, 100);
    },
  },
};
</script>

<style scoped>
.searchAddress {
  position: absolute;
  z-index: 4;
  width: 70%;
  height: 40px;
  left: 15%;
  top: 20px;
}
.google-autocomplete {
  background: white;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  height: 40px;
  text-transform: none;
  left: 15%;
  top: 20px;
}
.map {
  position: absolute;
}
.wrapper {
  position: relative;
  width: 100%;
  height: 400px;
}
</style>