<template>
  <div>
    <!-- <input
      type="file"
      @change="uploadMain"
      ref="upload_main"
      style="display: none"
    /> -->
    <!-- <v-card
      v-if="!main_image"
      @click="$refs.upload_main.click()"
      class="mb-0 mx-0 mx-md-15 pa-0 pa-md-15 py-10 py-md-15 border"
      color="grey lighten-1"
      style="border-radius: 30px"
      height="350px"
      align="center"
      ><div style="color: #525252; font-size: 40px">
        {{ "attractions_label_main_image" | localize }}<span class="req"> *</span>
      </div></v-card
    >
    <v-row no-gutters style="cursor: pointer">
      <input
        type="file"
        @change="uploadFirst"
        ref="upload_first"
        style="display: none"
      />
      <img
        v-if="main_image"
        @click="$refs.upload_main.click()"
        style="border-radius: 30px; width: 100%; object-fit: cover"
        class="ml-0 ml-md-15 mr-0 mr-md-15"
        height="350px;"
        align="center"
        :src="main_image"
      />
    </v-row> -->
    <span
      class="pl-1"
      style="display: flex; align-items: center; font-size: 16px"
      >{{ "attractions_add_images_label" | localize }}</span
    >
    <v-row class="mx-0 mx-md-15 mb-7 pt-2" no-gutters style="cursor: pointer"
      ><v-col class="col-3" style="padding: 0px !important">
        <input
          type="file"
          @change="uploadFirst"
          ref="upload_first"
          style="display: none"
        />
        <v-card
          v-if="!attraction.first_image"
          @click="$refs.upload_first.click()"
          class="py-4 py-md-14"
          color="grey lighten-1"
          style="border-radius: 20px; margin: 0px 10px 0px 10px"
          :height="
            $vuetify.breakpoint.xs || $vuetify.breakpoint.xs ? '80px' : '150px'
          "
          align="center"
          ><div class="label-image">
            {{ "attractions_label_image" | localize
            }}<span class="req"> *</span>
          </div></v-card
        >
        <div style="padding: 0px 10px 0px 10px">
          <img
            v-if="attraction.first_image"
            @click="$refs.upload_first.click()"
            style="border-radius: 20px; object-fit: cover; width: 100%"
            :height="
              $vuetify.breakpoint.xs || $vuetify.breakpoint.xs
                ? '80px'
                : '150px'
            "
            align="center"
            :src="attraction.first_image"
          />
        </div> </v-col
      ><v-col class="col-3" style="padding: 0px !important">
        <input
          type="file"
          @change="uploadSecond"
          ref="upload_second"
          style="display: none" /><v-card
          v-if="!attraction.second_image"
          @click="$refs.upload_second.click()"
          class="py-4 py-md-14"
          color="grey lighten-1"
          style="border-radius: 20px; margin: 0px 10px 0px 10px"
          :height="
            $vuetify.breakpoint.xs || $vuetify.breakpoint.xs ? '80px' : '150px'
          "
          align="center"
          ><div class="label-image">
            {{ "attractions_label_image" | localize
            }}<span class="req"> *</span>
          </div></v-card
        >
        <div style="padding: 0px 10px 0px 10px">
          <img
            v-if="attraction.second_image"
            @click="$refs.upload_second.click()"
            style="border-radius: 20px; object-fit: cover; width: 100%"
            :height="
              $vuetify.breakpoint.xs || $vuetify.breakpoint.xs
                ? '80px'
                : '150px'
            "
            align="center"
            :src="attraction.second_image"
          /></div></v-col
      ><v-col class="col-3" style="padding: 0px !important">
        <input
          type="file"
          @change="uploadThird"
          ref="upload_third"
          style="display: none"
        /><v-card
          @click="$refs.upload_third.click()"
          v-if="!attraction.third_image"
          class="py-4 py-md-14"
          color="grey lighten-1"
          style="border-radius: 20px; margin: 0px 10px 0px 10px"
          :height="
            $vuetify.breakpoint.xs || $vuetify.breakpoint.xs ? '80px' : '150px'
          "
          align="center"
          ><div class="label-image">
            {{ "attractions_label_image" | localize
            }}<span class="req"> *</span>
          </div></v-card
        >
        <div style="padding: 0px 10px 0px 10px">
          <img
            v-if="attraction.third_image"
            @click="$refs.upload_third.click()"
            style="border-radius: 20px; object-fit: cover; width: 100%"
            :height="
              $vuetify.breakpoint.xs || $vuetify.breakpoint.xs
                ? '80px'
                : '150px'
            "
            align="center"
            :src="attraction.third_image"
          />
        </div> </v-col
      ><v-col class="col-3" style="padding: 0px !important"
        ><input
          type="file"
          @change="uploadFour"
          ref="upload_four"
          style="display: none"
        /><v-card
          v-if="!attraction.four_image"
          @click="$refs.upload_four.click()"
          class="py-4 py-md-14"
          color="grey lighten-1"
          style="border-radius: 20px; margin: 0px 10px 0px 10px"
          :height="
            $vuetify.breakpoint.xs || $vuetify.breakpoint.xs ? '80px' : '150px'
          "
          align="center"
          ><div class="label-image">
            {{ "attractions_label_image" | localize
            }}<span class="req"> *</span>
          </div></v-card
        >
        <div style="padding: 0px 10px 0px 10px">
          <img
            v-if="attraction.four_image"
            @click="$refs.upload_four.click()"
            style="border-radius: 20px; object-fit: cover; width: 100%"
            :height="
              $vuetify.breakpoint.xs || $vuetify.breakpoint.xs
                ? '80px'
                : '150px'
            "
            align="center"
            :src="attraction.four_image"
          />
        </div>
      </v-col>
      <v-expand-transition>
        <span v-if="imagesError.length" class="caption ml-3 mt-3 red--text">{{
          imagesError.toString() | localize
        }}</span>
      </v-expand-transition>
      <v-expand-transition>
        <span v-if="imageError" class="caption ml-3 mt-3 red--text">{{
          imageError
        }}</span>
      </v-expand-transition>
    </v-row>
    <v-text-field
      outlined
      dense
      :label="'attractions_label_closest_bus' | localize"
      v-model.trim="attraction.closest_stop"
    >
      <!-- Joel corrections :error-messages="closest_stopError | localize" -->
    </v-text-field>
    <v-text-field
      outlined
      dense
      :label="'attractions_label_price' | localize"
      type="number"
      v-model.trim="attraction.price"
      :error-messages="priceError | localize"
      min="1"
      @keypress="isNumber($event)"
    >
      <template v-slot:label>
        <div>
          {{ "city_passes_placeholder_price_adults" | localize
          }}<span class="req"> *</span>
        </div>
      </template>
    </v-text-field>
    <v-text-field
      outlined
      dense
      :label="'city_passes_placeholder_price_kids' | localize"
      type="number"
      v-model="attraction.child_price"
      :error-messages="priceKidsError | localize"
      min="1"
      @keypress="isNumber($event)"
    >
      <template v-slot:label>
        <div>
          {{ "city_passes_placeholder_price_kids" | localize
          }}<span class="req"> *</span>
        </div>
      </template></v-text-field
    >
    <v-text-field
      outlined
      dense
      :label="'attractions_maximum_discount' | localize"
      :error-messages="discountError | localize"
      v-model="attraction.maximum_available_discount"
      type="number"
      min="1"
      max="100"
      @keypress="isNumber($event, attraction.maximum_available_discount)"
    >
      <template v-slot:label>
        <div>
          {{ "attractions_maximum_discount" | localize
          }}<span class="req"> *</span>
        </div>
      </template></v-text-field
    >
    <v-autocomplete
      dense
      outlined
      color="#E62076"
      item-color="#E62076"
      :item-value="'uid'"
      :item-text="'shop_name'"
      :error-messages="businessError | localize"
      v-model="attraction.business"
      attach
      :items="Object.values(businesses)"
      :menu-props="{ top: true, offsetY: true }"
      :label="'attractions_placeholder_choose_business' | localize"
      ><template #selection="{ item }">
        <v-chip small dark color="#E62076">{{ item.shop_name }}</v-chip>
      </template>
      <template v-slot:label>
        <div>
          {{ "attractions_placeholder_choose_business" | localize
          }}<span class="req"> *</span>
        </div>
      </template></v-autocomplete
    >
    <v-text-field
      outlined
      dense
      :label="'attractions_report_email' | localize"
      :error-messages="reportEmailError | localize"
      v-model="attraction.report_email"
    >
      <template v-slot:label>
        <div>
          {{ "attractions_report_email" | localize }}<span class="req"> *</span>
        </div>
      </template></v-text-field
    >
    <v-select
      v-if="mazatlan"
      dense
      outlined
      color="#E62076"
      item-color="#E62076"
      :error-messages="categoryError"
      v-model="attraction.category"
      attach
      :items="categories"
      :menu-props="{ top: true, offsetY: true }"
      ><template #selection="{ item }">
        <v-chip small dark color="#E62076">{{ item }}</v-chip>
      </template>
      <template v-slot:label>
        <div>{{ "Category of attraction" }}<span class="req"> *</span></div>
      </template></v-select
    >
    <span
      class="pl-1"
      style="display: flex; align-items: center; font-size: 16px"
      >{{ "attractions_prebooking_booking_label" | localize }}</span
    >
    <v-radio-group row mandatory v-model="attraction.is_booking_require">
      <v-radio :label="'button_yes' | localize" value="Yes"></v-radio>
      <v-radio :label="'button_no' | localize" value="No"></v-radio>
    </v-radio-group>
    <span
      class="pl-1"
      style="display: flex; align-items: center; font-size: 16px"
      >{{ "status_placeholder" | localize }}</span
    >
    <v-radio-group
      :disabled="statusDisabled"
      v-model="attraction.status"
      row
      mandatory
    >
      <v-radio
        :label="'attraction_active_label' | localize"
        value="Active"
      ></v-radio>
      <v-radio
        :label="'attraction_inactive_label' | localize"
        value="Inactive"
      ></v-radio>
    </v-radio-group>

    <v-checkbox
      color="#E62076"
      true-value="0"
      false-value="1"
      v-model="attraction.is_visible"
      :label="'city_passes_included_checkbox' | localize"
    ></v-checkbox>
    <!-- <v-row class="ml-0" align="center">
      <v-checkbox
        color="#E62076"
        class="mr-5"
        :label="'booking_required_label' | localize"
        v-model="isBookingFee"
      >
      </v-checkbox
      ><span style="color: #ff5252; font-size: 12px">{{
        bookingError[0]
      }}</span>
    </v-row>

    <div class="mb-1">
      <ckeditor
        v-if="isBookingFee"
        :editor="editor"
        v-model="booking_en"
        :config="editor_en_config"
      ></ckeditor>
      <div class="mt-6">
        <ckeditor
          v-if="isBookingFee"
          :editor="editor"
          v-model="booking_es"
          :config="editor_es_config"
        ></ckeditor>
      </div>
    </div> -->

    <v-row no-gutters>
      <v-btn text class="ml-auto" @click="cancel()">
        {{ "button_cancel" | localize }}
      </v-btn>
      <v-btn
        v-if="!editAttraction"
        color="#E62076"
        :loading="isDisabled"
        dark
        @click="next()"
        >{{ "cart_details_button_next" | localize }}</v-btn
      >
      <v-btn
        v-if="editAttraction"
        color="#E62076"
        :loading="isDisabled"
        dark
        @click="$v.$invalid ? $v.$touch() : $emit('update')"
        >{{ "button_update" | localize }}</v-btn
      >
    </v-row>
  </div>
</template>

<script>
import AttractionService from "@/requests/Attractions/AttractionsService.js";
import { validationMixin } from "vuelidate";
import { required, requiredIf, email } from "vuelidate/lib/validators";
//import Editor from "../../../../ckeditor5/build/ckeditor";
import "../../../../ckeditor5/build/translations/es";
import cityMixin from "@/mixins/cityMixin";

export default {
  mixins: [validationMixin, cityMixin],
  props: {
    editAttraction: {
      require: false,
    },
  },
  data: () => ({
    //editor: Editor,
    // editor_en_config: {},
    // editor_es_config: {},
    attraction: {
      first_image: null,
      second_image: null,
      third_image: null,
      four_image: null,
      closest_stop: null,
      business: null,
      price: null,
      child_price: null,
      status: "Active",
      is_visible: "1",
      maximum_available_discount: "",
      category: "",
      report_email: "",
      is_booking_require: "No",
    },
    booking_es: "",
    booking_en: "",
    child_price: null,
    main_image: null,
    businesses: [],
    isDisabled: false,
    isBookingFee: false,
    categories: ["A", "B", "C", "D"],
    statusDisabled: false,
    maxSize: 5 * 1024 * 1024,
    imageError: null,
  }),
  validations() {
    return {
      attraction: {
        price: {
          required,
        },
        child_price: {
          required,
        },
        // closest_stop: {
        //   required,
        // },
        business: {
          required,
        },
        first_image: {
          required,
        },
        second_image: {
          required,
        },
        third_image: {
          required,
        },
        four_image: {
          required,
        },
        maximum_available_discount: {
          required,
        },
        report_email: {
          required,
          email,
        },
        category: {
          required: requiredIf(function () {
            return this.mazatlan;
          }),
        },
      },
      // booking_es: {
      //   required,
      // },
      // booking_en: {
      //   required,
      // },
    };
  },
  mounted() {
    // this.editor_en_config.placeholder = `${this.$options.filters.localize(
    //   "booking_required_label"
    // )} EN`;
    // this.editor_es_config.placeholder = `${this.$options.filters.localize(
    //   "booking_required_label"
    // )} ES`;
    this.getBusinesses();
    this.getDetails();
  },
  watch: {
    "$v.$invalid": {
      handler(e) {
        if (this.editAttraction && !e) {
          this.$emit("off-error");
        }
      },
    },
    is_visible: {
      handler() {
        if (this.is_visible == "0") {
          this.statusDisabled = true;
          this.attraction.status = "Inactive";
        } else {
          this.statusDisabled = false;
        }
      },
    },
    "attraction.child_price": {
      handler(e) {
        console.log(e);
      },
    },
    attraction: {
      deep: true,
      handler() {
        if (this.editAttraction) {
          console.log(this.attraction);
          let updated_attr = {
            closest_stop: this.attraction.closest_stop
              ? this.attraction.closest_stop
              : "",
            price: parseInt(this.attraction.price),
            child_price: parseInt(this.attraction.child_price),
            status: this.attraction.status,
            business_uid: this.attraction.business,
            is_visible: this.attraction.is_visible,
            maximum_available_discount:
              this.attraction.maximum_available_discount,
            report_email: this.attraction.report_email,
            is_booking_require: this.attraction.is_booking_require,
            images: [
              {
                image: this.attraction.first_image,
                alt: "Image",
                sort: 0,
                id: this.editAttraction.images[0]
                  ? this.editAttraction.images[0].id
                  : "-id",
              },
              {
                image: this.attraction.second_image,
                alt: "Image",
                sort: 0,
                id: this.editAttraction.images[1]
                  ? this.editAttraction.images[1].id
                  : "-id",
              },
              {
                image: this.attraction.third_image,
                alt: "Image",
                sort: 0,
                id: this.editAttraction.images[2]
                  ? this.editAttraction.images[2].id
                  : "-id",
              },
              {
                image: this.attraction.four_image,
                alt: "Image",
                sort: 0,
                id: this.editAttraction.images[3]
                  ? this.editAttraction.images[3].id
                  : "-id",
              },
            ],
          };
          if (this.mazatlan) {
            updated_attr.category = this.attraction.category;
          }
          this.$emit("changedAttraction", updated_attr);
        }
      },
    },
  },
  computed: {
    priceError() {
      const errors = [];
      if (!this.$v.attraction.price.$dirty) {
        return errors;
      }
      !this.$v.attraction.price.required &&
        errors.push("city_passes_validation_price_adults_required");
      return errors;
    },
    priceKidsError() {
      const errors = [];
      if (!this.$v.attraction.child_price.$dirty) {
        return errors;
      }
      !this.$v.attraction.child_price.required &&
        errors.push("city_passes_validation_price_kids_required");
      return errors;
    },
    // closest_stopError() {
    //   const errors = [];
    //   if (!this.$v.closest_stop.$dirty) {
    //     return errors;
    //   }
    //   !this.$v.closest_stop.required &&
    //     errors.push("attractions_validation_closest_stop_required");
    //   return errors;
    // },
    discountError() {
      const errors = [];
      if (!this.$v.attraction.maximum_available_discount.$dirty) {
        return errors;
      }
      !this.$v.attraction.maximum_available_discount.required &&
        errors.push("attractions_maximum_discount_validation");
      return errors;
    },
    businessError() {
      const errors = [];
      if (!this.$v.attraction.business.$dirty) {
        return errors;
      }
      !this.$v.attraction.business.required &&
        errors.push("attractions_validation_business_required");
      return errors;
    },
    categoryError() {
      const errors = [];
      if (!this.$v.attraction.category.$dirty) {
        return errors;
      }
      !this.$v.attraction.category.required &&
        errors.push("Category of attraction is required");
      return errors;
    },
    imagesError() {
      const errors = [];
      // if (!this.$v.main_image.$dirty) {
      //   return errors;
      // }
      (!this.$v.attraction.first_image.required ||
        !this.$v.attraction.second_image.required ||
        !this.$v.attraction.third_image.required ||
        !this.$v.attraction.four_image.required) &&
        errors.push("attractions_validation_all_image_required");
      return errors;
    },
    reportEmailError() {
      const errors = [];
      if (!this.$v.attraction.report_email.$dirty) {
        return errors;
      }
      !this.$v.attraction.report_email.required &&
        errors.push("attractions_validation_report_email_required");
      if (!this.$v.attraction.report_email.email) {
        errors.push("reset_password_validation_incorrect_email");
        return errors;
      }
      return errors;
    },
    // bookingError() {
    //   const errors = [];
    //   if (
    //     (!this.$v.booking_es.$dirty && !this.$v.booking_en.$dirty) ||
    //     !this.isBookingFee
    //   ) {
    //     return errors;
    //   } else if (
    //     (!this.$v.booking_es.required || !this.$v.booking_en.required) &&
    //     this.isBookingFee
    //   ) {
    //     errors.push(
    //       "Booking requirements are obligatory if this setting is selected"
    //     );
    //   }
    //   return errors;
    // },
  },
  methods: {
    isNumber(evt, value) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode == 48) {
        return true;
      }
      if (
        charCode > 31 &&
        (charCode < 49 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        if (parseFloat(value + parseFloat(evt.key)) > 100) {
          evt.preventDefault();
        } else {
          if (
            parseFloat(value + parseFloat(evt.key)) < 1 ||
            (!value && evt.key == 0)
          ) {
            console.log(
              "work",
              parseFloat(value + parseFloat(evt.key)),
              !value,
              evt.key == 0
            );
            evt.preventDefault();
          } else return true;
        }
      }
    },
    getDetails() {
      this.attraction.closest_stop = this.editAttraction.closest_stop
        ? this.editAttraction.closest_stop
        : "";
      this.attraction.price = this.editAttraction.price;
      this.attraction.child_price = this.editAttraction.child_price;
      // this.main_image = this.editAttraction.images[0] ? this.editAttraction.images[0].thumbs[0] : "";
      this.attraction.first_image = this.editAttraction.images[0]
        ? this.editAttraction.images[0].thumbs[2]
        : "";
      this.attraction.second_image = this.editAttraction.images[1]
        ? this.editAttraction.images[1].thumbs[2]
        : "";
      this.attraction.third_image = this.editAttraction.images[2]
        ? this.editAttraction.images[2].thumbs[2]
        : "";
      this.attraction.four_image = this.editAttraction.images[3]
        ? this.editAttraction.images[3].thumbs[2]
        : "";
      this.attraction.status = this.editAttraction.status;
      this.attraction.business = this.editAttraction.business_uid;
      this.attraction.is_visible = this.editAttraction.is_visible;
      this.attraction.is_booking_require =
        this.editAttraction.is_booking_require;
      this.attraction.maximum_available_discount =
        this.editAttraction.maximum_available_discount;
      this.attraction.report_email = this.editAttraction.report_email;
      // if (this.editAttraction.content[0].booking_requirements) {
      //   this.isBookingFee = true;
      //   this.booking_en = this.editAttraction.content[0].booking_requirements;
      //   this.booking_es = this.editAttraction.content[1].booking_requirements;
      // }

      if (this.mazatlan) {
        this.attraction.category = this.editAttraction.category;
      }
    },
    async getBusinesses() {
      await AttractionService.getBusinesses().then((res) => {
        this.businesses = res.result;
        console.log(res);
      });
    },
    update() {
      // if (!this.isBookingFee) {
      //   this.booking_es = "booking";
      //   this.booking_en = "booking";
      // }
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isDisabled = true;
        //await AttractionService.deleteAttractionImage(this.editAttraction.uid, this.editAttraction.images[0].id)
        let data = {
          content: {
            en: {
              title: this.editAttraction.content[0].title,
              description: this.editAttraction.content[0].description,
              short_description:
                this.editAttraction.content[0].short_description,
              booking_requirements:
                this.editAttraction.content[0].booking_requirements,
              refund_policy: this.editAttraction.content[0].refund_policy,
            },
            es: {
              title: this.editAttraction.content[1].title,
              description: this.editAttraction.content[1].description,
              short_description:
                this.editAttraction.content[1].short_description,
              booking_requirements:
                this.editAttraction.content[1].booking_requirements,
              refund_policy: this.editAttraction.content[1].refund_policy,
            },
          },
          address: this.editAttraction.address,
          working_days: this.editAttraction.working_days,
          working_hours: this.editAttraction.working_hours,
          closest_stop: this.attraction.closest_stop
            ? this.attraction.closest_stop
            : "",
          price: parseInt(this.attraction.price),
          child_price: parseInt(this.attraction.child_price),
          geo_lat: parseFloat(this.editAttraction.geo_lat),
          geo_lon: parseFloat(this.editAttraction.geo_lon),
          images: [
            {
              image: this.attraction.first_image,
              alt: "Image",
              sort: 0,
              id: this.editAttraction.images[0]
                ? this.editAttraction.images[0].id
                : "-id",
            },
            {
              image: this.attraction.second_image,
              alt: "Image",
              sort: 0,
              id: this.editAttraction.images[1]
                ? this.editAttraction.images[1].id
                : "-id",
            },
            {
              image: this.attraction.third_image,
              alt: "Image",
              sort: 0,
              id: this.editAttraction.images[2]
                ? this.editAttraction.images[2].id
                : "-id",
            },
            {
              image: this.attraction.four_image,
              alt: "Image",
              sort: 0,
              id: this.editAttraction.images[3]
                ? this.editAttraction.images[3].id
                : "-id",
            },
          ],
          status: this.attraction.status,
          business_uid: this.attraction.business,
          is_visible: this.attraction.is_visible,
          maximum_available_discount:
            this.attraction.maximum_available_discount,
        };

        if (this.mazatlan) {
          data.category = this.attraction.category;
        }
        this.$emit("update", data);
      }
    },
    // uploadMain(e) {
    //   let file = e.srcElement.files[0];
    //   var reader = new FileReader();
    //   let that = this;
    //   reader.readAsDataURL(file);
    //   reader.onload = function () {
    //     that.main_image = reader.result;
    //     console.log(that.main_image);
    //   };
    //   reader.onerror = function (error) {
    //     console.log("Error: ", error);
    //   };
    // },
    uploadFirst(e) {
      let file = e.srcElement.files[0];
      if (file.size < this.maxSize) {
        var reader = new FileReader();
        let that = this;
        reader.readAsDataURL(file);
        reader.onload = function () {
          that.attraction.first_image = reader.result;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
        this.imageError = "";
      } else {
        this.imageError = "The image should not exceed 5 MB";
      }
    },
    uploadSecond(e) {
      let file = e.srcElement.files[0];
      if (file.size < this.maxSize) {
        var reader = new FileReader();
        let that = this;
        reader.readAsDataURL(file);
        reader.onload = function () {
          that.attraction.second_image = reader.result;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
        this.imageError = "";
      } else {
        this.imageError = "The image should not exceed 5 MB";
      }
    },
    uploadThird(e) {
      let file = e.srcElement.files[0];
      if (file.size < this.maxSize) {
        var reader = new FileReader();
        let that = this;
        reader.readAsDataURL(file);
        reader.onload = function () {
          that.attraction.third_image = reader.result;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
        this.imageError = "";
      } else {
        this.imageError = "The image should not exceed 5 MB";
      }
    },
    uploadFour(e) {
      let file = e.srcElement.files[0];
      if (file.size < this.maxSize) {
        var reader = new FileReader();
        let that = this;
        reader.readAsDataURL(file);
        reader.onload = function () {
          that.attraction.four_image = reader.result;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
        this.imageError = "";
      } else {
        this.imageError = "The image should not exceed 5 MB";
      }
    },
    cancel() {
      this.$emit("close_modal");
    },
    next() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isDisabled = true;
        // if (!this.isBookingFee) {
        //   this.booking_es = "";
        //   this.booking_en = "";
        // }
        this.$emit(
          "next",
          this.attraction.closest_stop ? this.attraction.closest_stop : "",
          parseInt(this.attraction.price),
          parseInt(this.attraction.child_price),
          this.attraction.business,
          this.attraction.first_image,
          this.attraction.second_image,
          this.attraction.third_image,
          this.attraction.four_image,
          // this.booking_es,
          // this.booking_en,
          this.attraction.category,
          this.attraction.status,
          this.attraction.is_booking_require,
          this.attraction.is_visible,
          this.attraction.maximum_available_discount,
          this.attraction.report_email
        );
      }
    },
  },
};
</script>

<style scoped>
.label-image {
  font-size: 25px !important;
  color: #525252;
}

@media (max-width: 600px) {
  .label-image {
    font-size: 18px !important;
  }
}
.req {
  color: red !important;
}
</style>