<template>
  <div>
    <v-row justify="center">
      <span
        class="mt-15 px-1 mb-4"
        style="
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 26px;
          line-height: 32px;
          text-align: center;
        "
        >{{ "attractions_label_other_suggested_attractions" | localize }}</span
      >
      <div
        class="mt-2 px-4 mb-5"
        style="text-align: center"
        v-for="(attraction, index) in otherAttraction"
        :key="index"
      >
        <div
          style="cursor: pointer"
          @click="
            $router.replace('/attractions/' + attraction.uid), $emit('replace')
          "
        >
          <div v-for="(image, i) in attraction.images" :key="i">
            <v-img
              style="
                border-radius: 10%;
                object-fit: cover;
                height: 200px !important;
              "
              width="200px"
              v-if="i == 0"
              :src="image.thumbs[2]"
              alt="no img"
            />
          </div>
          <h4 class="mt-4 block-text">
            {{ attraction.title }}
          </h4>
          <div style="color: black">
            <span class="block-text">
              {{ attraction.short_description.substr(0, 50) }}
            </span>
          </div>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
import AttractionsService from "../../requests/Attractions/AttractionsService";
export default {
  data: () => ({
    otherAttraction: [],
  }),
  mounted() {
    this.getAttractions();
  },
  methods: {
    async getAttractions() {
      //Get list all attractions
      await AttractionsService.getAttractions().then((res) => {
        //Filter should only active attraction
        let attractions = res.result.filter(
          (attraction) => attraction.status == "Active"
        );
        let indexList = []; //List for two random index
        let last_val = null; //Last value for random number
        //Cycle for get random number
        do {
          let val = Math.floor(Math.random() * attractions.length);
          if (val == last_val) continue;
          indexList.push(val);
          last_val = val;
        } while (indexList.length !== 2);
        //Added valid attractions in array for output for user
        for (let index = 0; index < 2; index++) {
          this.otherAttraction.push(
            attractions[indexList[index]].uid == this.$route.params.id //Check if I am not on this attraction
              ? attractions[
                  indexList[1] == 0 ? indexList[1] + 1 : indexList[1] - 1
                ]
              : attractions[indexList[index]]
          );
        }
      });
    },
  },
};
</script>

<style>
.block-text {
  display: inline-block;
  max-width: 200px;
  word-break: break-word;
}
</style>