<template>
  <div>
    <v-text-field
      outlined
      dense
      class="mt-3"
      :error-messages="titleError | localize"
      v-model="attraction.title"
    >
      <template v-slot:label>
        <div>
          {{ "attractions_placeholder_title" | localize
          }}<span class="req"> *</span>
        </div>
      </template>
    </v-text-field>
    <v-textarea
      outlined
      auto-grow
      :error-messages="short_descriptionError | localize"
      v-model="attraction.short_description"
      counter="120"
    >
      <template v-slot:label>
        <div>
          {{ "attractions_label_short_description" | localize
          }}<span class="req"> *</span>
        </div>
      </template></v-textarea
    >
    <div
      :style="
        editorError.length
          ? 'border: 2px solid #ff5252; border-radius: 3px'
          : ''
      "
    >
      <div class="input-placeholder">
        <ckeditor
          v-if="upload"
          :editor="editor"
          v-model="attraction.editorData"
          :config="editorConfig"
          style=""
        ></ckeditor>
        <div class="placeholder" v-if="!attraction.editorData">
          {{ "attractions_placeholder_description" | localize }}
          <span>*</span>
        </div>
      </div>
    </div>
    <span class="ml-3 error--text" style="font-size: 13px">{{
      editorError.toString() | localize
    }}</span>

    <!-- REFUND EDITOR -->
    <!-- <div
      class="mt-3"
      :style="
        refundPolicyError.length
          ? 'border: 2px solid #ff5252; border-radius: 3px'
          : ''
      "
    >
      <ckeditor
        v-if="upload"
        :editor="editor"
        v-model="refund_policy"
        :config="refundConfig"
      ></ckeditor>
    </div> -->

    <!-- <span class="ml-3 error--text" style="font-size: 13px">{{
      refundPolicyError.toString()
    }}</span> -->

    <!-- REFUND EDITOR -->

    <v-row no-gutters class="mt-5">
      <v-btn text class="ml-auto" @click="cancel()">
        {{ "button_cancel" | localize }}
      </v-btn>
      <v-btn
        v-if="!editAttraction"
        color="#E62076"
        dark
        @click="next()"
        :loading="isDisabled"
        >{{ "cart_details_button_next" | localize }}</v-btn
      >
      <v-btn
        v-if="editAttraction"
        color="#E62076"
        dark
        @click="updateAttraction()"
        :loading="isDisabled"
        >{{ "button_update" | localize }}</v-btn
      >
    </v-row>
  </div>
</template>

<script>
import Editor from "../../../../ckeditor5/build/ckeditor";
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import "../../../../ckeditor5/build/translations/es";
import cityMixin from "@/mixins/cityMixin";

export default {
  mixins: [validationMixin, cityMixin],
  props: {
    editAttraction: {
      require: false,
    },
  },
  data: () => ({
    editor: Editor,
    upload: false,
    attraction: {
      editorData: "",
      refund_policy: "",
      title: "",
      short_description: "",
    },
    isDisabled: false,
    editorConfig: {
      link: {
        addTargetToExternalLinks: true,
      },
    },
    refundConfig: {},
    userLocale: "",
  }),
  validations() {
    return {
      attraction: {
        title: {
          required,
          maxWords: this.maxWords,
        },
        short_description: {
          required,
          maxLength: maxLength(120),
          minLength: minLength(50),
        },
        editorData: {
          required,
        },
      },
    };
  },
  mounted() {
    console.log(this.editAttraction);
    this.userLocale = this.loggedUser
      ? this.loggedUser.locale
      : this.user.locale;
    if (this.editAttraction) {
      this.attraction.title = this.editAttraction.content[1].title;
      this.attraction.short_description =
        this.editAttraction.content[1].short_description;
      this.attraction.editorData = this.editAttraction.content[1].description;
      // this.refund_policy = this.editAttraction.content[1].refund_policy;
    }
    setTimeout(() => {
      this.upload = true;
    }, 80);
    this.editorConfig.placeholder = " ";
    // this.refundConfig.placeholder = this.$options.filters.localize(
    //   "refund_policy_label"
    // );
    this.editorConfig.language = this.userLocale;
    //this.refundConfig.language = this.userLocale;
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
    titleError() {
      const errors = [];
      if (!this.$v.attraction.title.$dirty) {
        return errors;
      }
      !this.$v.attraction.title.required &&
        errors.push("attractions_validation_title_required");

      !this.$v.attraction.title.maxWords &&
        errors.push("city_passes_title_length_validation");

      return errors;
    },
    short_descriptionError() {
      const errors = [];
      if (!this.$v.attraction.short_description.$dirty) {
        return errors;
      } else if (!this.$v.attraction.short_description.required) {
        errors.push("attractions_validation_short_description_required");
      }
      if (
        !this.$v.attraction.short_description.maxLength ||
        !this.$v.attraction.short_description.minLength
      ) {
        errors.push(
          `${
            this.mazatlan ? "mzt_" : ""
          }attractions_validation_short_description_max_length`
        );
      }
      return errors;
    },
    editorError() {
      const errors = [];
      if (!this.$v.attraction.editorData.$dirty) {
        return errors;
      }
      !this.$v.attraction.editorData.required &&
        errors.push("attractions_validation_description_required");
      return errors;
    },
    // refundPolicyError() {
    //   const errors = [];
    //   if (!this.$v.refund_policy.$dirty) {
    //     return errors;
    //   }
    //   !this.$v.refund_policy.required &&
    //     errors.push("Refund policy is required");
    //   return errors;
    // },
  },
  methods: {
    maxWords() {
      if (this.attraction.title.split(" ").length > 7) {
        return false;
      } else {
        return true;
      }
    },
    updateAttraction() {
      this.$v.attraction.$touch();
      if (!this.$v.attraction.$invalid) {
        this.$emit("update");
      }
    },
    cancel() {
      this.$emit("close_modal");
    },
    update() {
      this.$v.attraction.$touch();
      if (!this.$v.attraction.$invalid) {
        this.isDisabled = true;
        let data = {
          content: {
            es: {
              title: this.attraction.title,
              description: this.attraction.editorData,
              short_description: this.attraction.short_description,
              refund_policy: this.editAttraction.content[1].refund_policy,
              booking_requirements:
                this.editAttraction.content[0].booking_requirements,
            },
            en: {
              title: this.editAttraction.content[0].title,
              description: this.editAttraction.content[0].description,
              short_description:
                this.editAttraction.content[0].short_description,
              refund_policy: this.editAttraction.content[0].refund_policy,
              booking_requirements:
                this.editAttraction.content[1].booking_requirements,
            },
          },
          address: this.editAttraction.address,
          working_days: this.editAttraction.working_days,
          working_hours: this.editAttraction.working_hours,
          closest_stop: this.editAttraction.closest_stop,
          price: parseInt(this.editAttraction.price),
          child_price: parseInt(this.editAttraction.child_price),
          geo_lat: parseFloat(this.editAttraction.geo_lat),
          geo_lon: parseFloat(this.editAttraction.geo_lon),
          images: this.editAttraction.images,
          status: this.editAttraction.status,
          business_uid: this.editAttraction.business_uid,
          is_visible: this.editAttraction.is_visible,
          maximum_available_discount:
            this.editAttraction.maximum_available_discount,
        };
        console.log(data);
        this.$emit("update", data);
      }
    },
    next() {
      this.$v.attraction.$touch();
      if (!this.$v.attraction.$invalid) {
        this.isDisabled = true;
        this.$emit(
          "next",
          this.attraction.title,
          this.attraction.short_description,
          this.attraction.editorData
          //this.refund_policy
        );
      }
    },
  },
  watch: {
    '$v.$invalid': {
      handler(e) {
        if (this.editAttraction && !e) { 
          this.$emit('off-error')
        }
      }
    },
    attraction: {
      deep: true,
      handler() {
        if (this.editAttraction) {
          let updated_attr = {
            content: {
              es: {
                title: this.attraction.title,
                short_description: this.attraction.short_description,
                description: this.attraction.editorData,
              },
            },
          };
          console.log(updated_attr);
          this.$emit("changedAttraction", updated_attr);
        }
      },
    },
  },
};
</script>

<style scoped>
.req {
  color: red !important;
}
</style>