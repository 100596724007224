<template>
  <div>
    <!-- REFUND EDITOR -->
    <!-- :style="
        refundPolicyErrorEN.length
          ? 'border: 2px solid #ff5252; border-radius: 3px'
          : ''
      " -->
    <!-- <div class="mt-3">
      <ckeditor
        v-if="upload"
        :editor="editor"
        v-model="refund_policy_en"
        :config="refundConfigEN"
      ></ckeditor>
    </div> -->
    <!-- <span class="ml-3 error--text" style="font-size: 13px">{{
      refundPolicyErrorEN.toString()
    }}</span> -->
    <!-- :style="
        refundPolicyErrorES.length
          ? 'border: 2px solid #ff5252; border-radius: 3px'
          : ''
      " -->
    <!-- <div class="mt-3">
      <ckeditor
        v-if="upload"
        :editor="editor"
        v-model="refund_policy_es"
        :config="refundConfigES"
      ></ckeditor>
    </div> -->
    <!-- <span class="ml-3 error--text" style="font-size: 13px">{{
      refundPolicyErrorEN.toString()
    }}</span> -->

    <!-- Booking EDITOR -->
    <!-- <v-row class="ml-0" align="center">
      <v-checkbox
        color="#E62076"
        class="mr-5"
        :label="'booking_required_label' | localize"
        v-model="isBookingFee"
      >
      </v-checkbox
      ><span style="color: #ff5252; font-size: 12px">{{
        bookingError[0]
      }}</span>
    </v-row> -->

    <div class="mt-3 bookingEditor">
      <ckeditor
        v-if="isBookingFee && upload"
        :editor="editor"
        v-model="attraction.booking_en"
        :config="editor_en_config"
      ></ckeditor>
      <div class="mt-6">
        <ckeditor
          v-if="isBookingFee && upload"
          :editor="editor"
          v-model="attraction.booking_es"
          :config="editor_es_config"
        ></ckeditor>
      </div>
    </div>
    <v-row class="mt-3">
      <v-col>
        <v-textarea
          rows="3"
          :label="'attractions_conditions_en_placeholder' | localize"
          v-model="attraction.condition_en"
          dense
          outlined
          hide-details
        >
        </v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
          rows="3"
          :label="'attractions_conditions_es_placeholder' | localize"
          v-model="attraction.condition_es"
          dense
          outlined
        >
        </v-textarea>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-5">
      <v-btn text class="ml-auto" @click="cancel()">
        {{ "button_cancel" | localize }}
      </v-btn>
      <v-btn
        v-if="!editAttraction"
        color="#E62076"
        dark
        @click="next()"
        :loading="isDisabled"
        >{{ "button_create" | localize }}</v-btn
      >
      <v-btn
        v-if="editAttraction"
        color="#E62076"
        dark
        @click="$emit('update')"
        :loading="isDisabled"
        >{{ "button_update" | localize }}</v-btn
      >
    </v-row>
  </div>
</template>

<script>
import Editor from "../../../../ckeditor5/build/ckeditor";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
export default {
  mixins: [validationMixin],
  data: () => ({
    editor: Editor,
    upload: false,
    isDisabled: false,
    isBookingFee: true,
    refund_policy_es: "",
    refund_policy_en: "",
    attraction: {
      booking_en: "",
      booking_es: "",
      condition_en: "",
      condition_es: "",
    },
    userLocale: "",
    refundConfigEN: {},
    refundConfigES: {},
    editor_en_config: {
      link: {
        addTargetToExternalLinks: true,
      },
    },
    editor_es_config: {
      link: {
        addTargetToExternalLinks: true,
      },
    },
    bookingError: [],
  }),
  validations: {
    // refund_policy_es: {
    //   required,
    // },
    // refund_policy_en: {
    //   required,
    // },
    attraction: {
      booking_es: {
        required,
      },
      booking_en: {
        required,
      },
    },
  },
  mounted() {
    this.setEditorConfig();
    if (this.editAttraction !== undefined) {
      this.setData();
    }
    setTimeout(() => {
      this.upload = true;
    }, 120);
  },
  props: {
    editAttraction: {
      require: false,
    },
  },
  methods: {
    update() {
      this.isDisabled = true;
      let data = {
        content: {
          es: {
            title: this.editAttraction.content[1].title,
            description: this.editAttraction.content[1].description,
            short_description: this.editAttraction.content[1].short_description,
            refund_policy: this.refund_policy_es,
            booking_requirements: this.attraction.booking_es,
          },
          en: {
            title: this.editAttraction.content[0].title,
            description: this.editAttraction.content[0].description,
            short_description: this.editAttraction.content[0].short_description,
            refund_policy: this.refund_policy_en,
            booking_requirements: this.attraction.booking_en,
          },
        },
        address: this.editAttraction.address,
        working_days: this.editAttraction.working_days,
        working_hours: this.editAttraction.working_hours,
        closest_stop: this.editAttraction.closest_stop,
        price: parseInt(this.editAttraction.price),
        child_price: parseInt(this.editAttraction.child_price),
        geo_lat: parseFloat(this.editAttraction.geo_lat),
        geo_lon: parseFloat(this.editAttraction.geo_lon),
        images: this.editAttraction.images,
        status: this.editAttraction.status,
        business_uid: this.editAttraction.business_uid,
        is_visible: this.editAttraction.is_visible,
        maximum_available_discount:
          this.editAttraction.maximum_available_discount,
      };
      this.$emit("update", data);
    },
    next() {
      this.$emit(
        "next",
        this.attraction.condition_en,
        this.attraction.condition_es,
        this.attraction.booking_es,
        this.attraction.booking_en
      );
      // this.$v.refund_policy_es.$touch();
      // this.$v.refund_policy_en.$touch();
      // if (this.isBookingFee) {
      //   if (
      //     // !this.$v.refund_policy_en.$invalid &&
      //     // !this.$v.refund_policy_es.$invalid &&
      //     !this.$v.booking_en.$invalid &&
      //     !this.$v.booking_en.$invalid
      //   ) {
      //     this.$emit(
      //       "next",
      //       this.refund_policy_en,
      //       this.refund_policy_es,
      //       this.booking_es,
      //       this.booking_en
      //     );
      //   }
      // } else {
      // if (
      //   !this.$v.refund_policy_en.$invalid &&
      //   !this.$v.refund_policy_es.$invalid
      // ) {
      //   this.$emit(
      //   "next",
      //   this.refund_policy_en,
      //   this.refund_policy_es,
      //   this.booking_es,
      //   this.booking_en
      // );
      // }
      //}
    },
    setEditorConfig() {
      this.refundConfigEN.placeholder = `${this.$options.filters.localize(
        "refund_policy_label"
      )} EN`;
      this.refundConfigES.placeholder = `${this.$options.filters.localize(
        "refund_policy_label"
      )} ES`;
      this.editor_en_config.placeholder = `${this.$options.filters.localize(
        "attractions_prebooking_window_title"
      )} EN`;
      this.editor_es_config.placeholder = `${this.$options.filters.localize(
        "attractions_prebooking_window_title"
      )} ES`;
      this.refundConfigEN.language =
        this.refundConfigES.language =
        this.editor_en_config.language =
        this.editor_es_config.language =
          this.user.locale;
    },
    setData() {
      this.attraction.condition_en = this.editAttraction.content[0].conditions;
      this.attraction.condition_es = this.editAttraction.content[1].conditions;
      this.attraction.booking_en =
        this.editAttraction.content[0].booking_requirements;
      this.attraction.booking_es =
        this.editAttraction.content[1].booking_requirements;
      if (this.attraction.booking_en !== "") {
        this.isBookingFee = true;
      }
    },
    cancel() {
      this.$emit("close_modal");
    },
  },
  computed: {
    ...mapGetters(["user"]),
    // refundPolicyErrorEN() {
    //   const errors = [];
    //   if (!this.$v.refund_policy_en.$dirty) {
    //     return errors;
    //   }
    //   !this.$v.refund_policy_en.required &&
    //     errors.push("Refund policy is required");
    //   return errors;
    // },
    // refundPolicyErrorES() {
    //   const errors = [];
    //   if (!this.$v.refund_policy_es.$dirty) {
    //     return errors;
    //   }
    //   !this.$v.refund_policy_es.required &&
    //     errors.push("Refund policy is required");
    //   return errors;
    // },
    // bookingError() {
    //   const errors = [];
    //   if (
    //     (!this.$v.booking_es.$dirty && !this.$v.booking_en.$dirty) ||
    //     !this.isBookingFee
    //   ) {
    //     return errors;
    //   } else if (
    //     (!this.$v.booking_es.required || !this.$v.booking_en.required) &&
    //     this.isBookingFee
    //   ) {
    //     errors.push(
    //       "Booking requirements are obligatory if this setting is selected"
    //     );
    //   }
    //   return errors;
    // },
  },
  watch: {
    attraction: {
      deep: true,
      handler() {
        if (this.editAttraction) {
          let updated_attr = {
            booking: true,
            booking_en: this.attraction.booking_en,
            booking_es: this.attraction.booking_es,
            condition_en: this.attraction.condition_en,
            condition_es: this.attraction.condition_es,
          };
          this.$emit("changedAttraction", updated_attr);
        }
      },
    },
  },
};
</script>
<style>
.ck.ck-editor__editable > .ck-placeholder::after {
  content: "";
  color: red;
  margin-left: 143px !important;
}
.bookingEditor .ck.ck-editor__editable > .ck-placeholder::after {
  content: "";
  color: red;
  margin-left: 165px !important;
}
.ck.ck-editor__editable > .ck-placeholder {
  display: flex;
}
</style>
