<template>
  <v-main>
    <v-container>
      <attraction-detail-page />
    </v-container>
  </v-main>
</template>

<script>
import AttractionDetailPage from "../components/OurAttractions/AttractionDetailPage.vue";
// import ComponentTitle from "../components/UI/ComponentTitle.vue";
// import PageSteps from "../components/UI/PageSteps.vue";
export default {
  components: { AttractionDetailPage },
};
</script>

<style>
</style>