<template>
  <div>
    <v-row
      no-gutters
      v-for="(day, key) in days"
      :key="key"
      class="mb-4 justify-center"
    >
      <v-checkbox
        dense
        color="#E62076"
        style="width: 120px"
        class="checkDay my-auto mr-10 to-label"
        :label="day.day | localize"
        v-model="day.checked"
        hide-details
      ></v-checkbox>
      <div class="mx-auto mx-md-15">
        <v-select
          color="rgb(218, 218, 218)"
          item-color="#E62076"
          style="width: 150px"
          v-model="day.from"
          :disabled="day.checked ? false : true"
          class="from"
          dense
          hide-details
          outlined
          :menu-props="{ bottom: true, offsetY: true }"
          :items="Object.values(hours)"
          chips
          ><template #selection="{ item }">
            <v-chip
              small
              dark
              :color="day.checked ? '#E62076' : 'rgb(218, 218, 218)'"
              >{{ item }}</v-chip
            >
          </template></v-select
        >
      </div>
      <div class="mx-auto mx-md-15 mx-lg-0">
        <v-select
          :disabled="day.checked ? false : true"
          color="rgb(218, 218, 218)"
          item-color="#E62076"
          style="width: 150px"
          v-model="day.to"
          class="to"
          dense
          outlined
          hide-details
          :menu-props="{ bottom: true, offsetY: true }"
          :items="Object.values(hours)"
          chips
          ><template #selection="{ item }">
            <v-chip
              small
              dark
              :color="day.checked ? '#E62076' : 'rgb(218, 218, 218)'"
              >{{ item }}</v-chip
            >
          </template></v-select
        >
      </div>
    </v-row>
    <v-row no-gutters>
      <v-btn text class="ml-auto" @click="cancel()">
        {{ "button_cancel" | localize }}</v-btn
      >
      <v-btn
        v-if="!editAttraction"
        color="#E62076"
        dark
        @click="next()"
        :loading="isDisabled"
        >{{ "cart_details_button_next" | localize }}</v-btn
      >
      <v-btn
        v-if="editAttraction"
        color="#E62076"
        :loading="isDisabled"
        dark
        @click="$emit('update')"
        >{{ "button_update" | localize }}</v-btn
      >
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    editAttraction: {
      require: false,
    },
  },
  data: () => ({
    snackbar: false,
    socials: false,
    lang: null,
    isDisabled: false,
    hours: [
      "00:00",
      "00:30",
      "01:00",
      "01:30",
      "02:00",
      "02:30",
      "03:00",
      "03:30",
      "04:00",
      "04:30",
      "05:00",
      "05:30",
      "06:00",
      "06:30",
      "07:00",
      "07:30",
      "08:00",
      "08:30",
      "09:00",
      "09:30",
      "10:00",
      "10:30",
      "11:00",
      "11:30",
      "12:00",
      "12:30",
      "13:00",
      "13:30",
      "14:00",
      "14:30",
      "15:00",
      "15:30",
      "16:00",
      "16:30",
      "17:00",
      "17:30",
      "18:00",
      "18:30",
      "19:00",
      "19:30",
      "20:00",
      "20:30",
      "21:00",
      "21:30",
      "22:00",
      "22:30",
      "23:00",
      "23:30",
    ],
    days: [
      {
        name: "Mon",
        day: "city_passes_Monday",
        from: "09:00",
        to: "19:00",
        checked: true,
      },
      {
        name: "Tue",
        day: "city_passes_Tuesday",
        from: "09:00",
        to: "19:00",
        checked: true,
      },
      {
        name: "Wed",
        day: "city_passes_Wednesday",
        from: "09:00",
        to: "19:00",
        checked: true,
      },
      {
        name: "Thu",
        day: "city_passes_Thursday",
        from: "09:00",
        to: "19:00",
        checked: true,
      },
      {
        name: "Fri",
        day: "city_passes_Friday",
        from: "09:00",
        to: "19:00",
        checked: true,
      },
      {
        name: "Sat",
        day: "city_passes_Saturday",
        from: "09:00",
        to: "14:00",
        checked: true,
      },
      {
        name: "Sun",
        day: "city_passes_Sunday",
        from: "09:00",
        to: "19:00",
        checked: false,
      },
    ],
  }),
  mounted() {
    this.getEditHours();
  },
  methods: {
    getEditHours() {
      let working_days = this.editAttraction.working_days;
      let working_hours = this.editAttraction.working_hours;
      for (let i = 0; i < this.days.length; i++) {
        {
          if (working_days[`${this.days[i].name}`] === "on") {
            this.days[i].checked = true;
            this.days[i].from = working_hours[`${this.days[i].name}`].from;
            this.days[i].to = working_hours[`${this.days[i].name}`].to;
          } else {
            this.days[i].checked = false;
          }
        }
      }
    },
    update() {
      this.isDisabled = true;
      let working_days = {};
      let working_hours = {};
      for (let i = 0; i < this.days.length; i++) {
        if (this.days[i].checked) {
          working_days[`${this.days[i].name}`] = "on";
          working_hours[`${this.days[i].name}`] = {
            from: this.days[i].from,
            to: this.days[i].to,
          };
        }
      }
      let data = {
        content: {
          en: {
            title: this.editAttraction.content[0].title,
            description: this.editAttraction.content[0].description,
            short_description: this.editAttraction.content[0].short_description,
            refund_policy: this.editAttraction.content[0].refund_policy,
            booking_requirements:
              this.editAttraction.content[0].booking_requirements,
          },
          es: {
            title: this.editAttraction.content[1].title,
            description: this.editAttraction.content[1].description,
            short_description: this.editAttraction.content[1].short_description,
            refund_policy: this.editAttraction.content[1].refund_policy,
            booking_requirements:
              this.editAttraction.content[1].booking_requirements,
          },
        },
        address: this.editAttraction.address,
        working_days: working_days,
        working_hours: working_hours,
        closest_stop: this.editAttraction.closest_stop,
        price: parseInt(this.editAttraction.price),
        child_price: parseInt(this.editAttraction.child_price),
        geo_lat: parseFloat(this.editAttraction.geo_lat),
        geo_lon: parseFloat(this.editAttraction.geo_lon),
        images: this.editAttraction.images,
        status: this.editAttraction.status,
        business_uid: this.editAttraction.business_uid,
        is_visible: this.editAttraction.is_visible,
        maximum_available_discount:
          this.editAttraction.maximum_available_discount,
      };
      console.log(data);
      this.$emit("update", data);
    },
    cancel() {
      this.$emit("close_modal");
    },
    next() {
      this.isDisabled = true;
      this.$emit("next", this.days);
    },
  },
  watch: {
    days: {
      deep: true,
      handler() {
        if (this.editAttraction) {
          let updated_attr = {};

          let working_days = {};
          let working_hours = {};
          for (let i = 0; i < this.days.length; i++) {
            if (this.days[i].checked) {
              working_days[`${this.days[i].name}`] = "on";
              working_hours[`${this.days[i].name}`] = {
                from: this.days[i].from,
                to: this.days[i].to,
              };
            }
          }
          updated_attr.working_days = working_days;
          updated_attr.working_hours = working_hours;

          this.$emit("changedAttraction", updated_attr);
        }
      },
    },
  },
};
</script>