<template>
  <v-dialog
    :retain-focus="false"
    persistent
    v-model="attractionModal"
    max-width="1200px"
  >
    <v-snackbar top color="error" v-model="errorSnackbar">
      <v-row align="center">
        <v-icon class="mx-3">mdi-alert</v-icon>
        <v-col>
          <span> {{ "attractions_edit_validation_error" | localize }}</span>
        </v-col>
      </v-row>
    </v-snackbar>
    <v-card>
      <div v-if="showLoader" style="height: 400px">
        <v-row no-gutters align="center" justify="center">
          <v-progress-circular
            class="mx-auto"
            style="margin-top: 180px"
            indeterminate
            color="#e62076"
          ></v-progress-circular>
        </v-row>
        <v-row class="mt-5" no-gutters align="center" justify="center">
          <div
            style="color: #e62076; opacity: 0.75"
            class="text-h5 blink"
            v-if="uploadPercentage != 0 && uploadPercentage != 100"
          >
            {{ uploadPercentage }}%
          </div>
          <div
            style="color: #e62076; opacity: 0.75"
            class="text-h5 blink"
            v-if="uploadPercentage == 100"
          >
            {{ "attractions_almost_done_label" | localize }}
          </div>
        </v-row>
      </div>
      <v-tabs v-model="tab" color="#E62076" grow v-show="!showLoader">
        <v-tab :class="tabs[0].hasError ? 'tab_error' : ''"
          >{{ "attraction_label_information_en" | localize
          }}<v-icon class="ml-1" v-if="tabs[0].hasError" color="error" small
            >mdi-alert</v-icon
          >
        </v-tab>
        <v-tab :class="tabs[1].hasError ? 'tab_error' : ''"
          >{{ "attraction_label_information_es" | localize
          }}<v-icon class="ml-1" v-if="tabs[1].hasError" color="error" small
            >mdi-alert</v-icon
          ></v-tab
        >
        <v-tab>{{ "attractions_label_location" | localize }}</v-tab>
        <v-tab>{{ "attractions_label_open_hours" | localize }}</v-tab>
        <v-tab :class="tabs[4].hasError ? 'tab_error' : ''"
          >{{ "attractions_label_details" | localize
          }}<v-icon class="ml-1" v-if="tabs[4].hasError" color="error" small
            >mdi-alert</v-icon
          >
        </v-tab>
        <v-tab>{{ "attractions_refund_booking_page" | localize }} </v-tab>
      </v-tabs>
      <v-tabs-items v-show="!showLoader" v-model="tab">
        <v-tab-item>
          <v-col class="px-8" v-show="tab == 0">
            <add-info-en
              ref="info_en"
              @close_modal="closeModal"
              @off-error="tabs[0].hasError = false"
              :editAttraction="editAttraction"
              @update="update"
              @changedAttraction="changedAttraction"
            />
          </v-col>
        </v-tab-item>
        <v-tab-item v-show="tab == 1">
          <v-col class="px-8">
            <add-info-es
              ref="info_es"
              @close_modal="closeModal"
              @off-error="tabs[1].hasError = false"
              :editAttraction="editAttraction"
              @update="update"
              @changedAttraction="changedAttraction"
            />
          </v-col>
        </v-tab-item>
        <v-tab-item v-show="tab == 2">
          <v-col class="px-8">
            <add-location
              @close_modal="closeModal"
              :editAttraction="editAttraction"
              @update="update"
              @changedAttraction="changedAttraction"
            />
          </v-col>
        </v-tab-item>
        <v-tab-item v-show="tab == 3">
          <v-col class="px-8">
            <add-hours
              @close_modal="closeModal"
              :editAttraction="editAttraction"
              @update="update"
              @changedAttraction="changedAttraction"
            />
          </v-col>
        </v-tab-item>
        <v-tab-item v-show="tab == 4">
          <v-col class="px-8">
            <add-images
              ref="details"
              @close_modal="closeModal"
              @off-error="tabs[4].hasError = false"
              :editAttraction="editAttraction"
              @update="update"
              @changedAttraction="changedAttraction"
            />
          </v-col>
        </v-tab-item>
        <v-tab-item v-show="tab == 5">
          <v-col class="px-8">
            <add-refunds-and-booking
              @close_modal="closeModal"
              :editAttraction="editAttraction"
              @update="update"
              @changedAttraction="changedAttraction"
            />
          </v-col>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import addImages from "./addAtractions/addImages.vue";
import addLocation from "./addAtractions/addLocation.vue";
import addHours from "./addAtractions/addHours.vue";
import addInfoEs from "./addAtractions/addInfoES.vue";
import addInfoEn from "./addAtractions/addInfoEN.vue";
//import AttractionService from "@/requests/Attractions/AttractionsService.js";
import axios from "axios";
import store from "@/store";
import AddRefundsAndBooking from "./addAtractions/addRefundsAndBooking.vue";
import CitypassService from "@/requests/Citypass/CitypassService";

export default {
  components: {
    addImages,
    addLocation,
    addInfoEn,
    addInfoEs,
    addHours,
    AddRefundsAndBooking,
  },
  props: {
    editAttraction: {
      require: false,
    },
  },
  data: () => ({
    tab: 0,
    attractionModal: true,
    showLoader: true,
    uploadPercentage: 0,
    attraction: {},
    errorSnackbar: false,
    tabs: [
      {
        id: 0,
        hasError: false,
      },
      {
        id: 1,
        hasError: false,
      },
      {
        id: 2,
        hasError: false,
      },
      {
        id: 3,
        hasError: false,
      },
      {
        id: 4,
        hasError: false,
      },
      {
        id: 5,
        hasError: false,
      },
    ],
  }),
  mounted() {
    this.getCopyEdited();
    setTimeout(this.hideLoader, 500);
  },
  methods: {
    getCopyEdited() {
      this.attraction = {
        content: {
          en: {
            title: this.editAttraction.title,
            description: this.editAttraction.editorData,
            short_description: this.editAttraction.short_description,
            conditions: this.editAttraction.content[0].conditions,
            booking_requirements:
              this.editAttraction.content[0].booking_requirements,
          },
          es: {
            title:
              this.editAttraction.content.length > 1
                ? this.editAttraction.content[1].title
                : "",
            description:
              this.editAttraction.content.length > 1
                ? this.editAttraction.content[1].description
                : "",
            short_description:
              this.editAttraction.content.length > 1
                ? this.editAttraction.content[1].short_description
                : "",
            conditions: this.editAttraction.content[1].conditions,
            booking_requirements:
              this.editAttraction.content[1].booking_requirements,
          },
        },
        address: this.editAttraction.address,
        working_days: this.editAttraction.working_days,
        working_hours: this.editAttraction.working_hours,
        closest_stop: this.editAttraction.closest_stop,
        is_booking_require: this.editAttraction.is_booking_require,
        price: parseInt(this.editAttraction.price),
        child_price: parseInt(this.editAttraction.child_price),
        geo_lat: parseFloat(this.editAttraction.geo_lat),
        geo_lon: parseFloat(this.editAttraction.geo_lon),
        images: this.editAttraction.images,
        status: this.editAttraction.status,
        business_uid: this.editAttraction.business_uid,
        is_visible: this.editAttraction.is_visible,
        maximum_available_discount:
          this.editAttraction.maximum_available_discount,
        report_email: this.editAttraction.report_email,
      };
      console.log(this.attraction);
    },
    closeModal() {
      this.attractionModal = false;
    },
    changedAttraction(attraction) {
      if (attraction.content && attraction.content.en) {
        this.attraction.content.en.title = attraction.content.en.title;
        this.attraction.content.en.short_description =
          attraction.content.en.short_description;
        this.attraction.content.en.description =
          attraction.content.en.description;
      } else if (attraction.content && attraction.content.es) {
        this.attraction.content.es.title = attraction.content.es.title;
        this.attraction.content.es.short_description =
          attraction.content.es.short_description;
        this.attraction.content.es.description =
          attraction.content.es.description;
      } else if (attraction.booking) {
        this.attraction.content.en.booking_requirements = attraction.booking_en;
        this.attraction.content.es.booking_requirements = attraction.booking_es;
        this.attraction.content.en.conditions = attraction.condition_en;
        this.attraction.content.es.conditions = attraction.condition_es;
      } else {
        let result = Object.assign(
          {},
          this.attraction,
          ...Object.keys(this.attraction).map(
            (k) => k in attraction && { [k]: attraction[k] }
          )
        );
        this.attraction = result;
      }
      console.log(this.attraction);
    },
    async update() {
      this.$refs.info_en?.$v.$touch();
      this.$refs.info_es?.$v.$touch();
      this.$refs.details?.$v.$touch();

      this.tabs[0].hasError = this.$refs.info_en.$v.$invalid;
      this.tabs[1].hasError = this.$refs.info_es?.$v.$invalid;
      this.tabs[4].hasError = this.$refs.details?.$v.$invalid;

      let errors = this.tabs.filter((el) => el.hasError);

      if (!errors.length) {
        this.showLoader = true;
        axios
          .post(
            process.env.VUE_APP_API +
              `citypass/attraction/` +
              this.editAttraction.uid,
            this.attraction,
            {
              headers: {
                Authorization: `Bearer ${store.getters.loggedUser.token}`,
                "X-CITYPASS-KEY": `${process.env.VUE_APP_X_API_KEY}`,
              },
              onUploadProgress: function (progressEvent) {
                this.uploadPercentage = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                );
              }.bind(this),
            }
          )
          .then((res) => {
            if (!res?.data.result) {
              this.showLoader = false;
              alert(res?.data.messages.error);
            } else {
              if (
                this.editAttraction.status == "Active" &&
                this.attraction.status == "Inactive" &&
                this.attraction.is_visible != "0"
              ) {
                CitypassService.deleteAttractionFromCityPass(
                  this.editAttraction.uid
                ).then((res) => {
                  this.showLoader = false;
                  console.log(res);
                  this.attractionModal = false;
                  this.$emit("changed_attraction");
                });
              } else {
                this.showLoader = false;
                this.attractionModal = false;
                this.$emit("changed_attraction");
              }
            }
          });
        console.log(this.attraction);
      }
      else {
        this.errorSnackbar = true
      }
    },
    hideLoader() {
      this.showLoader = false;
    },
  },
  watch: {
    attractionModal: {
      handler() {
        this.attractionModal = false;
        this.$emit("close_modal");
      },
    },
    editAttraction: {
      handler() {
        console.log(this.editAttraction);
      },
    },
    tab: {
      deep: true,
      handler() {
        this.showLoader = true;
        setTimeout(this.hideLoader, 500);
      },
    },
  },
};
</script>

<style>
.blink {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}
.tab_error {
  color: red !important;
}
</style>