<template>
  <div>
    <PageSteps
      :name="attraction.title"
      v-if="$route.name != 'mobile-attractions' && !isFromMain"
    />
    <v-btn
      @click="$router.push({ name: 'home' })"
      color="black"
      icon
      v-if="isFromMain && !isMobile" 
      x-large
    >
      <v-row no-gutters justify="center" align="center">
        <v-col class="col-12 pa-0">
          <img src="@/assets/mobileBackButton.svg" />
        </v-col>
      </v-row>
    </v-btn>
    <div
      style="position: relative"
      :class="
        !$vuetify.breakpoint.xs
          ? 'mt-5'
          : $vuetify.breakpoint.xs && $route.name == 'mobile-attractions'
          ? 'mt-2'
          : 'mt-5'
      "
    >
      <v-row
        align="center"
        :justify="isMobile ? 'center' : 'start'"
        class="mx-5 mx-sm-2 mb-2 mb-sm-8"
        :class="{ 'text-center': isMobile }"
      >
        <h2 v-if="isMobile">{{ attraction.title }}</h2>
        <h1 v-if="!isMobile">{{ attraction.title }}</h1>
        <v-divider v-if="!isMobile" class="mt-5 ml-3" />
      </v-row>
      <v-btn
        v-if="admin"
        fab
        color="#E62076"
        right
        dark
        small
        top
        absolute
        style="margin-top: 35px"
        @click="openModal"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </div>
    <loader v-if="showLoader" />
    <div v-else>
      <v-row class="d-none d-md-flex" v-if="!$vuetify.breakpoint.xs">
        <v-col cols="3">
          <AttractionAddressCard
            :attraction="attraction"
            :rulesType="rulesType"
            :showRulesModal="showRulesModal"
          />
          <OtherSuggestedAtraction
            @replace="(showLoader = true), getAttractions()"
            :key="suggestedAttractionKey"
          />
        </v-col>
        <v-col cols="9" class="px-10">
          <AttractionsDescription :attraction="attraction" />
        </v-col>
      </v-row>
      <v-row class="d-block d-md-none" v-else>
        <v-col cols="12">
          <AttractionsDescription :attraction="attraction" />
          <AttractionAddressCard
            :attraction="attraction"
            :rulesType="rulesType"
            :showRulesModal="showRulesModal"
          />
        </v-col>
        <!-- <v-col cols="12" class="px-10">
          <OtherSuggestedAtraction
            @replace="getAttractions"
            :key="suggestedAttractionKey"
          />
        </v-col> -->
      </v-row>
    </div>

    <edit-attraction-modal
      ref="modal"
      v-if="attractionModal"
      @close_modal="closeModal"
      @changed_attraction="getAttractions"
      :editAttraction="editAttraction"
    />
  </div>
</template>

<script>
import AttractionAddressCard from "./AttractionAddressCard.vue";
import OtherSuggestedAtraction from "./OtherSuggestedAtraction.vue";
import AttractionsDescription from "./AttractionsDescription.vue";
import AttractionService from "@/requests/Attractions/AttractionsService.js";
import PageSteps from "@/components/UI/PageSteps.vue";
import { mapGetters } from "vuex";
import editAttractionModal from "./editAttractionModal.vue";
import Loader from "../UI/Loader.vue";
import mobile from "@/mixins/mobileMixin";

export default {
  components: {
    PageSteps,
    AttractionAddressCard,
    OtherSuggestedAtraction,
    AttractionsDescription,
    editAttractionModal,
    Loader,
  },
  data: () => ({
    attraction: {},
    admin: false,
    attractionModal: false,
    editAttraction: {},
    suggestedAttractionKey: 0,
    showRulesModal: false,
    rulesType: null,
    showLoader: true,
    working_hoursValues: [],
    isFromMain: false,
  }),
  mixins: [mobile],
  watch: {
    loggedUser: {
      handler() {
        if (this.loggedUser && this.loggedUser.admin) {
          this.admin = true;
        }
      },
    },
    "loggedUser.locale": {
      deep: true,
      handler() {
        this.getAttractions();
      },
    },
    "user.locale": {
      deep: true,
      handler() {
        this.getAttractions();
      },
    },
  },
  mounted() {
    this.$vuetify.goTo(0, 0);
    this.isFromMain = this.$route.query.isMain;
    if (this.loggedUser && this.loggedUser.admin) {
      this.admin = true;
    }
    this.getAttractions();
  },
  computed: {
    ...mapGetters(["loggedUser"]),
    ...mapGetters(["user"]),
  },
  methods: {
    async openModal() {
      await AttractionService.getAttractionForAdmin(this.$route.params.id).then(
        (res) => {
          this.editAttraction = res.result;
          this.attractionModal = true;
        }
      );
    },
    closeModal() {
      this.attractionModal = false;
    },
    async getAttractions() {
      await AttractionService.getAttraction(this.$route.params.id).then(
        (res) => {
          this.attraction = this.filterAttractions(res.result);
          this.suggestedAttractionKey++;
          this.$vuetify.goTo(0, 0);
          this.showLoader = false;
        }
      );
    },
    filterAttractions(attractions) {
      let attractionsList = [{ ...attractions }];
      let filteredWorkingHours = [];
      for (let i = 0; i < attractionsList.length; i++) {
        if (typeof attractionsList[i].working_hours == "object") {
          filteredWorkingHours.push(attractionsList[i]);
        }
      }

      attractionsList = filteredWorkingHours;
      for (let index = 0; index < attractionsList.length; index++) {
        console.log()
        this.working_hoursValues = Object.values(
          attractionsList[index].working_hours
        );
        let working_hoursKey = Object.keys(
          attractionsList[index].working_hours
        );
        let working_hours = [];

        for (let i = 0; i < this.working_hoursValues.length; i++) {
          working_hours.push({
            day: working_hoursKey[i],
            from: this.working_hoursValues[i].from,
            to: this.working_hoursValues[i].to,
          });
        }

        let result = [];
        let currentGroup = [working_hours[0]];

        for (let i = 1; i < working_hours.length; i++) {
          // Check if days are consecutive and have the same working hours
          if (
            working_hours[i].from === currentGroup[0].from &&
            working_hours[i].to === currentGroup[0].to &&
            this.areDaysConsecutive(
              currentGroup[currentGroup.length - 1].day,
              working_hours[i].day
            )
          ) {
            // Add day to the current group
            currentGroup.push(working_hours[i]);
          } else {
            if (currentGroup.length > 1) {
              // If there is more than one day in the group, combine them and add to the result
              result.push({
                day: this.combineConsecutiveDays(currentGroup),
                from: currentGroup[0].from,
                to: currentGroup[0].to,
              });
            } else {
              // If there is only one day in the group, add it to the result
              result.push(currentGroup[0]);
            }
            currentGroup = [working_hours[i]]; // Start a new group
          }
        }

        if (currentGroup.length > 1) {
          result.push({
            day: this.combineConsecutiveDays(currentGroup),
            from: currentGroup[0].from,
            to: currentGroup[0].to,
          });
        } else {
          result.push(currentGroup[0]);
        }

        // Create markers based on grouped working hours
        attractionsList[index].working_hours = [...result];
        return attractionsList[0];
      }
    },
    // Check if two days of the week are consecutive
    areDaysConsecutive(day1, day2) {
      const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
      return (
        daysOfWeek.indexOf(day1) + 1 === daysOfWeek.indexOf(day2) ||
        daysOfWeek.indexOf(day1) - 6 === daysOfWeek.indexOf(day2)
      );
    },

    // Combine consecutive days into a single string
    combineConsecutiveDays(group) {
      return group.map((day) => day.day).join("-");
    },
  },
};
</script>

<style></style>
