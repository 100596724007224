<template>
  <div class="card-address" :class="{ mobile: isMobile }">
    <v-col class="ml-sm-2">
      <!-- <p v-if="attraction.booking_requirements && isMobile">
        <u
          class="font-weight-bold"
          style="cursor: pointer; color: #3e81de"
          @click="showModal('booking')"
          >{{ "booking_required_label" | localize }}</u
        >
      </p> -->
      <v-row
        class="mb-3"
        no-gutters
        align="center"
        v-if="attraction.conditions && isMobile"
      >
        <img
          src="@/assets/prebookingIcon.svg"
          style="padding-top: 4px; align-self: start"
        />
        <v-col cols="10" class="ml-2">
          <span class="font-weight-bold" style="color: #f44336">
            <div v-html="attraction.conditions" />
          </span>
        </v-col>
      </v-row>
      <!-- <p v-if="isMobile">
        <u
          class="font-weight-bold"
          style="cursor: pointer; color: #3e81de"
          @click="showModal('refundPolicy')"
          >{{ "refund_policy_label" | localize }}</u
        >
      </p> -->
      <v-row align="center" no-gutters>
        <img
          src="@/assets/Vector.svg"
          style="margin-top: 3px; align-self: start"
        />
        <v-col cols="10" class="ml-2">
          <span
            ><u
              ><a
                :href="`https://www.google.com/maps/search/?api=1&query=${attraction.geo_lat},${attraction.geo_lon}`"
                target="_blank"
                style="text-decoration: none; color: black"
                >{{ attraction.address }}</a
              ></u
            ></span
          >
        </v-col>
      </v-row>
      <v-row
        align="center"
        no-gutters
        class="mt-3"
        v-if="attraction.closest_stop && attraction.closest_stop !== ''"
      >
        <img
          src="@/assets/Buses.svg"
          style="margin-top: 3px; align-self: start"
        />
        <v-col cols="10" class="ml-2">
          <span
            v-if="attraction.closest_stop && attraction.closest_stop !== ''"
            >{{ attraction.closest_stop }}</span
          >
        </v-col>
      </v-row>
      <v-row align="center" no-gutters class="mt-3">
        <img
          src="@/assets/Time.svg"
          style="margin-top: 3px; align-self: start"
        />
        <v-col cols="10" class="ml-2">
          <span v-for="(key, index) in attraction.working_hours" :key="index">
            <span v-if="key.day.length > 3">
              {{ key.day.substr(0, 3) | localize }}-{{
                key.day.substr(key.day.length - 3, key.day.length) | localize
              }}:&nbsp;{{ key.from + "-" + key.to
              }}<span v-if="index < attraction.working_hours.length - 1"
                >,&nbsp;</span
              >
            </span>
            <span v-else>
              {{ key.day.substr(0, 3) | localize }}:&nbsp;{{
                key.from + "-" + key.to
              }}<span v-if="index < attraction.working_hours.length - 1"
                >,&nbsp;</span
              >
            </span>
          </span>
        </v-col>
      </v-row>
      <!-- <br /> -->

      <v-row
        class="mt-2"
        no-gutters
        align="center"
        v-if="attraction.conditions && !isMobile"
      >
        <img
          src="@/assets/prebookingIcon.svg"
          style="padding-top: 4px; align-self: start"
        />
        <v-col cols="10" class="ml-2">
          <span class="font-weight-bold" style="color: #f44336">
            <div v-html="attraction.conditions" />
          </span>
        </v-col>
      </v-row>

      <!-- <p class="mt-3" v-if="attraction.booking_requirements && !isMobile">
        <u
          class="font-weight-bold"
          style="cursor: pointer; color: #3e81de; font-size: 17.5px"
          @click="showModal('booking')"
          >{{ "booking_required_label" | localize }}</u
        >
      </p> -->

      <!-- <p v-if="!isMobile">
        <u
          class="font-weight-bold"
          style="cursor: pointer; color: #3e81de"
          @click="showModal('refundPolicy')"
          >{{ "refund_policy_label" | localize }}</u
        >
      </p> -->

      <!-- JOEL CORRECTIONS
      REMOVE PRICE -->

      <!-- <span class="font-weight-bold"
        >{{ "attractions_label_price" | localize }}: MX${{
          attraction.price
        }}</span
      > -->

      <!-- JOEL CORRECTIONS
      REMOVE PRICE -->
      <GmapMap
        v-if="!isMobile"
        class="mt-5"
        :center="{
          lat: parseFloat(attraction.geo_lat),
          lng: parseFloat(attraction.geo_lon),
        }"
        :zoom="12"
        map-type-id="terrain"
        :style="
          $vuetify.breakpoint.xs
            ? 'width: 100%; height: 300px'
            : 'width: 232px; height: 226px'
        "
        ><GmapMarker
          :position="{
            lat: parseFloat(attraction.geo_lat),
            lng: parseFloat(attraction.geo_lon),
          }"
          :clickable="true"
      /></GmapMap>
    </v-col>
    <terms-modal
      v-if="isShowRefundedPolicy"
      :rules="rules"
      @close_modal="isShowRefundedPolicy = false"
      :text="text"
    />
  </div>
</template>

<script>
import TermsModal from "../UI/TermsModal.vue";
import mobile from "@/mixins/mobileMixin";

export default {
  components: {
    TermsModal,
  },
  mixins: [mobile],
  props: {
    attraction: {
      require: true,
    },
    rulesType: {
      require: false,
    },
  },
  data: () => ({
    isShowRefundedPolicy: false,
    text: null,
    rules: null,
  }),
  methods: {
    showModal(rules) {
      this.rules = rules;
      if (rules == "booking") {
        this.text = this.attraction.booking_requirements;
      } else if (rules == "refundPolicy") {
        this.text = this.attraction.refund_policy;
      }
      this.isShowRefundedPolicy = true;
    },
  },
  watch: {
    attraction: {
      handler() {
        if (this.$route.hash) {
          if (this.$route.hash == "#bookingRequired") {
            this.showModal("booking");
          }
          if (this.$route.hash == "#refundPolicy") {
            this.showModal("refundPolicy");
          }
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.card-address {
  border-left: 2px solid #e62076;
  &.mobile {
    border-left: none;
  }
}
</style>