<template>
  <div>
    <div>
      <v-col>
        <swiper
          ref="swiperRef"
          style="max-width: 724px; align-self: start; margin-left: 0px"
          :options="swiperOption"
          :style="{
            '--swiper-navigation-color': '#ffffff',
            '--swiper-pagination-color': '#ffffff',
          }"
          :key="key"
        >
          <swiper-slide
            v-for="(image, index) in attraction.images"
            :key="index"
          >
            <img :src="image.thumbs[0]" class="activeImage" />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
        <!-- <img :src="attraction.images[0].thumbs[0]" class="activeImage" /> -->
        <v-row no-gutters v-if="attraction.images">
          <img
            class="image"
            v-for="(image, index) in attraction.images"
            :src="image.thumbs[2]"
            :key="index"
            @click="toSlide(index)"
          />
        </v-row>
      </v-col>
    </div>
    <v-col>
      <div class="desc" v-html="attraction.description"></div>
    </v-col>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    attraction: {
      require: true,
    },
  },
  data: () => ({
    swiperOption: {
      slidesPerView: 1,
      spaceBetween: 0,
      slidesPerGroup: 1,
      loopFillGroupWithBlank: false,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      loop: true,
    },
    key: 0,
    index: null,
    imageActive: "",
    images: [
      "https://www.proceso.com.mx/u/fotografias/m/2021/3/27/f850x638-132500_209989_4727.jpg",
      "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/11/ea/cc/0c/ven-y-enamorate-del-beisbol.jpg?w=1200&h=1200&s=1",
      "https://visitguadalajara.com/sites/default/files/basic-slides/1469286016-charros_saludando.jpg",
      "https://culturacolectiva-cultura-colectiva-prod.cdn.arcpublishing.com/resizer/X_odJMEfu4BKgpIpSolzpQzH-Qo=/1024x768/filters:format(jpg):quality(70)/cloudfront-us-east-1.images.arcpublishing.com/culturacolectiva/IOOH2XNJ4FC2XGDRTYF4DLQWKU.jpg",
    ],
  }),
  methods: {
    toSlide(i) {
      // Wrong ref
      // this.$refs.swiperOptionTop.swiper.slideTo(i)
      console.log(this.$refs.swiperRef);
      this.$refs.swiperRef.$swiper.slideTo(i + 1, 0);
    },
  },
  watch: {
    attraction: {
      handler() {
        if (this.attraction) {
          this.key++;
          this.editorData = this.attraction.description;
          if (this.attraction.images.length > 0) {
            this.imageActive = this.attraction.images[0].thumbs[0];
          }
        }
      },
    },
  },
};
</script>

<style>
.image {
  width: 170px;
  height: 166px;
  border-radius: 10%;
  margin-right: 15px;
  cursor: pointer;
  object-fit: cover;
}

.booking_title {
  font-size: 20px;
  font-weight: 500;
}

.activeImage {
  width: 724px;
  height: 428px;
  border-radius: 2%;
  object-fit: cover;
}

.desc {
  width: 724px;
}

.desc p {
  text-align: justify;
}

@media (max-width: 600px) {
  .image {
    width: calc(25% - 8px);
    display: flex;
    margin-right: 10px;
    height: 80px;
  }

  .image:nth-child(4n) {
    width: calc(25% - 8px);
    margin-right: 0px;
  }

  .activeImage {
    width: 100%;
    height: 350px;
    border-radius: 2%;
    object-fit: cover;
  }

  .desc {
    width: 100%;
    text-align: justify;
  }

  .desc p {
    text-align: justify;
  }
}

@media (max-width: 1264px) and (min-width: 600px) {
  .image {
    width: calc(25% - 15px);
    display: flex;
    margin-right: 20px;
    height: 150px;
  }

  .image:nth-child(4n) {
    width: calc(25% - 15px);
    display: flex;
    margin-right: 0px;
    height: 150px;
  }

  .activeImage {
    width: 100%;
    height: 400px;
    border-radius: 2%;
    object-fit: cover;
  }

  .desc {
    width: 100%;
  }

  .desc p {
    text-align: justify;
  }
}

/* ckeditor5 styles */
.marker-green {
  background-color: #62f962;
}

.marker-pink {
  background-color: #fc7899;
}

.marker-blue {
  background-color: #72ccfd;
}

.pen-red {
  background-color: transparent;
  color: #e71313;
}

.pen-green {
  background-color: transparent;
  color: #128a00;
}

/* ckeditor5 styles */
</style>